import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Input, Select, Button, Badge, Form } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { getFacilitatorServices } from "../../../pages/facilitator/redux/action";
import {
  isFacilitator,
  isInvestor,
  isStartup,
  isSuperAdmin,
} from "../../../Utils";
import AddGrantModal from "../../../pages/grantMarketPlace/AddGrantModal";
import "./style.scss";
import { dealTypesDetails } from "../../../constants";
import { useLocation } from "react-router-dom";
import { getUser } from "../../../Utils/isAuth";

function MarketPlaceTabHeader({
  searchFilter,
  selectFilter,
  isServiceMarketplace,
  onServiceChange,
  isGrantMarketPlace,
  setOpenPopUp,
  showDealType,
  onDealTypeChange,
  showSettings,
  handleFilterModal,
  filtercount,
  handleSearch,
  changeIp,
  typeIP,
  setCurrentPage,
  search,
}) {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { facilitatorServices } = useSelector(
    (state) => state.facilitatorsPage
  );
  const { type_of_account = "" } = getUser();
  const { pathname } = useLocation();
  const [role, setRole] = useState("facilitator");
  const [service, setService] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [dealType, setDealType] = useState(undefined);
  const showFilters = useMemo(() => {
    return isServiceMarketplace;
  }, [isServiceMarketplace]);

  const showAddGrant = useMemo(() => {
    return isGrantMarketPlace && isSuperAdmin();
  }, [isGrantMarketPlace]);

  useEffect(() => {
    if (showFilters) {
      dispatch(getFacilitatorServices());
    }
  }, [dispatch, showFilters]);

  return (
    <div className="marketplace_tab_header">
      <Row gutter={[8]}>
        <Col md={showSettings ? 6 : 6} className="center-filter-and-search">
          {showSettings ? (
            <div className="mkt_header_search">
              <Form onFinish={handleSearch} style={{ width: "100%" }}>
                <Input
                  className="search_btn"
                  value={search}
                  onChange={searchFilter}
                  placeholder="Search"
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </Form>
            </div>
          ) : (
            <div className="mkt_header_search">
              <Input
                className="search_btn"
                value={search}
                onChange={searchFilter}
                placeholder="Search Projects"
                prefix={<SearchOutlined />}
                allowClear
              />
            </div>
          )}
        </Col>
        {pathname === "/ips" &&
          (isStartup() || isInvestor() || isFacilitator()) && (
            <Col md={4}>
              <div className="mkt_header_select">
                <Select
                  placeholder="Select a Type of IP"
                  size="large"
                  onChange={(value) => {
                    if (changeIp) {
                      changeIp(value);
                      setCurrentPage(1);
                    }
                  }}
                  value={typeIP}
                >
                  {isInvestor() ? (
                    <>
                      <Option value={"all"}>All</Option>
                      <Option value={"yourRegisterIPs"}>
                        IPs for registration
                      </Option>
                      <Option value={"yourSellIPs"}>IPs for sale</Option>
                      <Option value={"investedIPs"}>Invested IPs</Option>
                    </>
                  ) : (
                    <>
                      <Option value={"all"}>All IPs</Option>
                      <Option value={true}>IPs for registration</Option>
                      <Option value={false}>IPs for sale</Option>
                    </>
                  )}
                </Select>
              </div>
            </Col>
          )}
        {showSettings && (
          <div className="badge-container">
            <Badge count={filtercount}>
              <FilterOutlined
                className="filter-btn"
                onClick={handleFilterModal}
              />
            </Badge>
          </div>
        )}
        {showDealType && (
          <Col md={6}>
            <div className="mkt_header_select">
              <Select
                placeholder="Select Deal Type"
                size="large"
                onChange={(value) => {
                  onDealTypeChange(value);
                  setDealType(value);
                }}
                value={dealType}
                allowClear
              >
                {dealTypesDetails.map((dealType) => (
                  <Option key={dealType.value} value={dealType.value}>
                    {dealType.title}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        )}
        {showFilters && (
          <>
            <Col md={6}>
              <div className="mkt_header_select">
                <Select
                  placeholder="Select a "
                  size="large"
                  onChange={(value) => {
                    selectFilter(value);
                    setRole(value);
                    if (value == "facilitator") {
                      setService(null);
                    }
                  }}
                  value={role}
                >
                  <Option value="svc_org">Organizers</Option>
                  <Option value="facilitator">Facilitator</Option>
                </Select>
              </div>
            </Col>
            {role === "facilitator" && (
              <Col md={6}>
                <div className="mkt_header_select">
                  <Select
                    placeholder="Select Service"
                    size="large"
                    onChange={(value) => {
                      onServiceChange(value);
                      setService(value);
                    }}
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA?.children
                        ?.toLowerCase()
                        ?.localeCompare(optionB?.children?.toLowerCase())
                    }
                    value={service}
                    allowClear
                  >
                    {facilitatorServices.map((service) => (
                      <Option key={service.label} value={service.label}>
                        {service.value}
                      </Option>
                    ))}
                    <Option value="other">Other</Option>
                  </Select>
                </div>
              </Col>
            )}
          </>
        )}
        {showAddGrant && (
          <Col md={18} className="header-control">
            <Button
              type="primary"
              className="add-button"
              onClick={() => {
                setModalOpen(true);
                setOpenPopUp(false);
              }}
            >
              Add a New Grant
            </Button>
          </Col>
        )}
      </Row>
      <AddGrantModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
}

export default MarketPlaceTabHeader;
