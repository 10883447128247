import { useEffect, useState } from "react";
import { Menu, Avatar, Row, Col, Dropdown, Tooltip, Collapse } from "antd";
import Icon from "@ant-design/icons";
import { MoreOutlined, ShoppingOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/main-logo-svg.svg";
import "./Sidenav.scss";

import {
  getUser,
  removeLongevityMail,
  removeUserSession,
} from "../../Utils/isAuth";
import { useMemo } from "react";
import { getFirstLater, getImageURL } from "../../Utils";

import { TooltipContents } from "../../Utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SidenavHandler from "./SidenavHandler";
import _ from "lodash";
import { ExactPage } from "../../redux/auth/action";
import { addSectorFilter, setCommercialDbs } from "./redux/action";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function Sidenav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key1, setKey1] = useState("0");
  const user = getUser();

  const { sideNavItems, navBarOptions } = SidenavHandler();

  const chatList = useSelector((state) => state.chat.chatList);
  const { logout } = useAuth0();

  const signOutMenu = (
    <Menu className="edit-menu">
      {user?.roles === "Admin" && (
        <Menu.Item
          onClick={() => navigate("/user-listing")}
          className="edit-menu__item"
          key="edit-menu-item-3"
        >
          <p>User Administration</p>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={(event) => {
          navigate("/my-projects");
        }}
        className="edit-menu__item"
        key="edit-menu-item-0"
      >
        <p>My Projects</p>
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          navigate("/settings");
        }}
        className="edit-menu__item"
        key="edit-menu-item-1"
      >
        <p>Settings</p>
      </Menu.Item>

      <Menu.Item
        onClick={(event) => {
          navigate("/admin/manage-access");
        }}
        className="edit-menu__item"
        key="edit-menu-item-12"
      >
        <p>Manage access</p>
      </Menu.Item>

      <Menu.Item
        onClick={(event) => {
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSfw4oHHcBXZx0Fm7vpE9FpD36ILaGtWTRnzBEhuYnAYDj0RxQ/viewform?usp=sf_link",
            "_blank"
          );
        }}
        className="edit-menu__item"
        key="edit-menu-item-6"
      >
        <p>Submit Feedback</p>
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          removeUserSession();
          removeLongevityMail();
          logout({ localOnly: true });
          //dispatch(ExactPage(3));
          dispatch(ExactPage(0));
          navigate("/registration");
        }}
        className="edit-menu__item"
        key="edit-menu-item-2"
      >
        <p>Sign Out</p>
      </Menu.Item>
    </Menu>
  );

  const isUndeaded = useMemo(() => {
    let hasUnreaded = false;
    chatList.forEach((el) => {
      if (el.message_count > 0) {
        hasUnreaded = true;
      }
    });
    return hasUnreaded;
  }, [chatList]);

  const handleSidebarClick = (title, key) => {
    setKey1(key);
    if (title === "Dashboards") {
      dispatch(setCommercialDbs(true));
    }else{
      dispatch(addSectorFilter(title));
    }
  };

  const SideMenuItem = ({ sidebarItem }) =>
    sidebarItem.map(
      ({ checkUserRole, icon, title, path, key, description }) => {
        return (
          checkUserRole &&
          (user?.categories?.[0] === "all" ||
            user?.categories?.includes(title)) && (
            <Menu.Item
              key={key}
              onClick={() => {
                handleSidebarClick(title, key);
              }}
              className={`side-menu-item ${
                key1 === key && "ant-menu-item-selected"
              }`}
            >
              <Tooltip placement="right" mouseLeaveDelay={0} title={title}>
                <NavLink to={path}>
                  <span
                    className="label"
                    style={{
                      fontWeight: title === "All Projects" ? 700 : 400,
                    }}
                  >
                    {title}
                  </span>
                </NavLink>
              </Tooltip>
            </Menu.Item>
          )
        );
      }
    );

  return (
    <>
      <div id="sidebar_content">
        <div
          className="brand"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            onClick={() => {
              handleSidebarClick("All Projects", "0");
              navigate("/");
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <div className="menu">
          <Menu theme="light" mode="inline" selectedKeys={[key1]}>
            <Collapse
              expandIconPosition="end"
              ghost
              defaultActiveKey={[1, 2, 3]}
            >
              {Object.entries(navBarOptions).map(
                ([header, sidebarItem], index) => (
                  <>
                    {sidebarItem.length > 1 ? (
                      <CollapsePanel
                        className="sidepanel-header"
                        header={header}
                        key={index}
                      >
                        <SideMenuItem sidebarItem={sidebarItem} />
                      </CollapsePanel>
                    ) : (
                      <SideMenuItem sidebarItem={sidebarItem} />
                    )}
                  </>
                )
              )}
            </Collapse>

            {/* {sideNavItems.map(
              ({ checkUserRole, icon, title, path, key, description }) => {
                return (
                  checkUserRole && (
                    <Menu.Item
                      key={key}
                      onClick={() => {
                        handleSidebarClick(title, key);
                      }}
                      className={`side-menu-item ${
                        key1 === key && "ant-menu-item-selected"
                      }`}
                    >
                      <Tooltip
                        placement="right"
                        mouseLeaveDelay={0}
                        title={TooltipContents[description]}
                      >
                        <NavLink to={path}>
                          {key === "15" ? (
                            <ShoppingOutlined
                              style={{ fontSize: "32px" }}
                              className={`icon-nav icon-nav-longevity`}
                            />
                          ) : (
                            <Icon
                              component={icon}
                              style={{ fontSize: "32px" }}
                              className={`icon-nav ${
                                key === "6" && isUndeaded ? "unreaded" : ""
                              }`}
                            />
                          )}
                          <span className="label">{title}</span>
                        </NavLink>
                      </Tooltip>
                    </Menu.Item>
                  )
                );
              }
            )} */}
          </Menu>
        </div>

        <div id="user_sidebar_button">
          <hr />
          <Row gutter={[5]}>
            <Col sm={4}>
              <div className="user_sidenav">
                <Avatar
                  className="user_sidenav"
                  src={getImageURL(user?.userLogo || "")}
                >
                  {getFirstLater(user?.email || "")}
                </Avatar>
              </div>
            </Col>
            <Col sm={17}>
              <div className="username_sidenav">
                <h3 className="username_sidenav">{`${user?.email}`}</h3>
              </div>
            </Col>

            <Col sm={2} className="sign-out-more">
              <Dropdown
                overlay={signOutMenu}
                trigger="click"
                placement="topRight"
              >
                <MoreOutlined />
              </Dropdown>
            </Col>
            <Col sm={1}></Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
