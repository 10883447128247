import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { getUser } from "../Utils/isAuth";
import PrivateRoute, { AdminOnly, AnonymousOnly } from "./PrivateRoute";
import Registration from "../layout/Auth/Registration";
import Main from "../layout/dashboard/Main";
import DealDetail from "../pages/DealDetail";
import CreatePassword from "../layout/Auth/CreatePassword";
import SelectionPage from "../pages/SelectionPage";
import InvestedView from "../pages/InvestedView";
import Complited from "../pages/createDeal/forms/Complited";
import DealDetails from "../pages/dealDetail/DealDetail";
import AdminInvestment from "../pages/adminInvestment/AdminInvestment";
import { Deals } from "../pages/deals";
import { InvestPage } from "../pages/investPage";
// import { MarketplacePage } from "../pages/marketplacePage";
import { MAMarketplacePage } from "../pages/MAmarketplace";
import { Users } from "../pages/users";
import AdminTopOrgarnizers from "../pages/adminTopOrganizers/adminTopOrganizers";
import ServiceMarketPlace from "../pages/serviceMarketPlace/ServiceMarketPlace";
import Facilitator from "../pages/facilitator/Facilitator";
import IPInvestPage from "../pages/investPage/IPInvestPage";
import MyProjects from "../pages/My Projects/MyprojectsPage";

import "../assets/styles/onboarding.less";
import NewGrantMarketPlace from "../pages/grantMarketPlace/NewGrantMarketPlace";
import Ips from "../pages/ips/Ips";
import IPMarketplacePage from "../pages/IntellectualPropertyMarketPlace";
import IPDetails from "../pages/ipDetail";
import AdminIPInvestment from "../pages/adminIPInvestment/AdminIPInvestment";
import LongevityMarketplace from "../pages/LongevityMarketplace";
import LongevityView from "../pages/LongevityMarketplace/LongevityView";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import VerifyingSocialLogin from "../layout/Auth/VerifyingSocialLogin";
import InvestorRelation from "../pages/InvestorRelations/InvestorRelation";
import UserJourney from "../pages/userJourney/UserJourney";
import HomePage from "../layout/Auth/Home";
import Register from "../layout/Auth/Register";
import UserInviteCallBack from "../pages/UserInviteCallBack/UserInviteCallBack";
import TwoFactorVerification from "../layout/Auth/TwoFactorVerification";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const CreateIpV2 = React.lazy(() => import("../pages/createIpV2"));
const CreateDeal = React.lazy(() => import("../pages/createDeal/CreateDeal"));
const Settings = React.lazy(() => import("../pages/settings/Settings"));
const MarketplacePage = React.lazy(() => import("../pages/marketplacePage/MarketplacePage"));
const OnboardingPage = React.lazy(() =>
  import("../pages/onBoardingPage/OnBoardingPage")
);
const ChatPage = React.lazy(() => import("../pages/chatPage/ChatPage"));
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const AdminManageAccess = React.lazy(() => import("../pages/AdminAccessManager/AdminManageAccess"));
const CommercialDashboards = React.lazy(() => import("../pages/CommercialDashboards/CommercialDashboards"));

const AppRoutes = () => {
  const [isAuthDone, setIsAuthDone] = useState(false);
  const user = getUser();

  return (
    <Routes>
      {/* <Route
        path="/"
        element={<Navigate to={!user ? "/" : "/home"} />}
      /> */}
      <Route
        path="/home"
        element={<HomePage setIsAuthDone={setIsAuthDone} />}
      />
      <Route path="/register" element={<Register isAuthDone={isAuthDone} />} />

      <Route path="/login" element={<Registration isAuthDone={isAuthDone} />} />
      <Route path="/2fa" element={<AnonymousOnly><TwoFactorVerification /></AnonymousOnly>} />
      <Route
        path="/invite-callback/:token/:projectId"
        element={<UserInviteCallBack />

        }
      />

      <Route
        path="/user-journey"
        element={
          <Main>
            <PrivateRoute>
              <UserJourney />
            </PrivateRoute>
          </Main>
        }
      />
      <Route path="/callback" element={<VerifyingSocialLogin />} />
      <Route
        path="/settings"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <Main>
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            </Main>
          </React.Suspense>
        }
      />
      <Route
        path="/investments"
        element={
          <Main>
            <PrivateRoute>
              <AdminInvestment />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/ip-investments"
        element={
          <Main>
            <PrivateRoute>
              <AdminIPInvestment />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/organizers"
        element={
          <Main>
            <PrivateRoute>
              <AdminTopOrgarnizers />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/admin/manage-access"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
          <Main>
            <PrivateRoute> 
              <AdminManageAccess />
            </PrivateRoute>
          </Main>
          </React.Suspense>
        }
      />

      <Route
        path="/service-marketplace"
        element={
          <Main>
            <PrivateRoute>
              <ServiceMarketPlace />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/grant-marketplace"
        element={
          <Main>
            <PrivateRoute>
              <NewGrantMarketPlace />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/dashboards"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <Main>
              <PrivateRoute>
                <CommercialDashboards />
              </PrivateRoute>
            </Main>
          </React.Suspense>
        }
      />


      <Route
        path="/"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
          <Main>
            <PrivateRoute>
              <MarketplacePage />
            </PrivateRoute>
          </Main>
          </React.Suspense>
        }
      />
      <Route
        path="/ip-marketplace"
        element={
          <Main>
            <PrivateRoute>
              <IPMarketplacePage />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/MAmarketplace"
        element={
          <Main>
            <PrivateRoute>
              <MAMarketplacePage />
            </PrivateRoute>
          </Main>
        }
      />

      <Route
        path="/chat"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <Main hideBlock={true}>
              <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
            </Main>
          </React.Suspense>
        }
      />

      <Route
        path="/deals"
        element={
          <Main>
            <PrivateRoute>
              <Deals />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/ips"
        element={
          <Main>
            <PrivateRoute>
              <Ips />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/dealdetail"
        element={
          <Main>
            <PrivateRoute>
              <DealDetail />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/longevity-marketplace"
        element={
          <Main>
            <PrivateRoute>
              <LongevityMarketplace />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/longevity-marketplace/:id"
        element={
          <Main>
            <PrivateRoute>
              <LongevityView />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/my-projects"
        element={
          <Main>
            <PrivateRoute>
              <MyProjects />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/project/:id"
        element={
        <React.Suspense
                    fallback={<Spin className="spinner" indicator={antIcon} />}
                  >
          <Main>
            <PrivateRoute>
              <DealDetails />
            </PrivateRoute>
          </Main>
          </React.Suspense>
        }
      />
      <Route
        path="/ipdetails/:id"
        element={
          <Main>
            <PrivateRoute>
              <IPDetails />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/investedview"
        element={
          <Main>
            <PrivateRoute>
              <InvestedView />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/investor-relations"
        element={
          // <React.Suspense
          //   fallback={<Spin className="spinner" indicator={antIcon} />}
          // >
          <Main>
            <PrivateRoute>
              <InvestorRelation />
            </PrivateRoute>
          </Main>
          // </React.Suspense>
        }
      />
      <Route
        path="/onboarding/:step"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <OnboardingPage />
          </React.Suspense>
        }
      />
      <Route
        path="/create-projects"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <AdminOnly>
              <CreateDeal />
            </AdminOnly>
          </React.Suspense>
        }
      />
      <Route
        path="/edit-projects/:dealID"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <PrivateRoute>
              <CreateDeal />
            </PrivateRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/create-ip"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <PrivateRoute>
              <CreateIpV2 />
            </PrivateRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/edit-register-ip/:id"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <PrivateRoute>
              <CreateIpV2 />
            </PrivateRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/edit-sell-ip/:id"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <PrivateRoute>
              <CreateIpV2 />
            </PrivateRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/sell-ip"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <PrivateRoute>
              <CreateIpV2 />
            </PrivateRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/invest"
        element={
          <PrivateRoute>
            <InvestPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ip-invest"
        element={
          <PrivateRoute>
            <IPInvestPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/complited"
        element={
          <PrivateRoute>
            <Complited />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <React.Suspense
            fallback={<Spin className="spinner" indicator={antIcon} />}
          >
            <Main>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </Main>
          </React.Suspense>
        }
      />
      <Route
        path="/facilitator"
        element={
          <Main>
            <PrivateRoute>
              <Facilitator />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/users"
        element={
          <Main>
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/user-listing"
        element={
          <Main>
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          </Main>
        }
      />
      <Route
        path="/account-type/:uid/:email_token"
        element={<SelectionPage />}
      />
      <Route
        path="/forgot-password/:uid/:email_token"
        element={<CreatePassword />}
      />
      <Route
        path="verify-email/:uid/:email_token"
        element={<SelectionPage />}
      />
      {/* <Route path="/*" element={<Navigate to={!!user ? "/" : "/home"} />} /> */}
      <Route path="/*" element={<Navigate to={!!user ? "/" : "/login"} />} />
    </Routes>
  );
};

export default AppRoutes;
