import { Modal, Spin } from "antd";
import React from "react";
import DealDetailHeader from "./components/DealDetailHeader";
import DealDetailIntro from "./components/DealDetailIntro";
import InvestorDealDetail from "./InvestorDealDetail";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./style.scss";
import AdminDealDetail from "./AdminDealDetail";
import DocumentsList from "./tabs/dataRoom/DocumentsList";
import useManageDealDetail from "./useManageDealDetail";
import IframeComponent from "./components/IframeComponent";
import ProjectNotFound from "./components/ProjectNotFound";
import LogsDisplay from "./LogsDisplay";


const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const { error } = Modal;



function DealDetails() {
  const allowedRoles = [ "Admin", "Partner", "Organisation" ]
  const [ showLogs, setShowLogs ] = React.useState(false)
  const {
    dealDetails,
    loading,
    userRole,
    user,
    projectData,
    displayDataRoom,
    setDisplayDataRoom,
    showProjectDataFiles,
    setShowProjectDataFiles,
    dataRoomLink,
    projectNotFound,
    projectLogs
  } = useManageDealDetail()

  if (projectNotFound) return <ProjectNotFound />;
  console.log("projectDataprojectDataprojectDataprojectDataprojectData: ", projectData?.countProjectViews)
  return (
    dealDetails && (
      <div className="deal-detail">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        {(allowedRoles.includes(userRole) ) && (
          <>
            <DealDetailHeader
              projectData={projectData?.project}
              countViews={projectData?.countProjectViews}
              userRole={userRole}
              setDisplayDataRoom={setDisplayDataRoom}
              displayDataRoom={displayDataRoom}
              setShowProjectDataFiles={setShowProjectDataFiles}
              showProjectDataFiles={showProjectDataFiles}
              setShowLogs={setShowLogs}
              showProjectLogs={showLogs}
            />
            {showProjectDataFiles === "data_room" ? (
              <>
                {dataRoomLink ? (
                  <><div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      onClick={() => window.open(dataRoomLink, "_blank")}
                    >
                      Go To Data Room
                    </Button>
                  </div>
                    <IframeComponent title={"Data Room"} source={dataRoomLink} />
                  </>
                )
                : <h1 style={{textAlign:"center", padding:"1rem"}}>Project has no data room link.</h1>
                }
                
                {/* <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "dataRoomDocs" && !item?.isHidden
                    ) || []
                  }
                  dataRoomLink={dataRoomLink}
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                /> */}
              </>
            ) : showProjectDataFiles === "tier_5_data" ? (
              projectData?.project?.tierFiveDocumentUrl ? 
              <IframeComponent title={"TierFive"} source={projectData?.project?.tierFiveDocumentUrl} />
              : <h1 style={{textAlign:"center", padding:"1rem"}}>Project has no tier 5 document.</h1>
            ) : showProjectDataFiles === "pricing_documents" ? (
              <DocumentsList
                  documents={
                    projectData?.project?.links?.filter(
                      (item) => item?.type === "pricing_documents" && !item?.isHidden
                    ) || []
                  }
                  imagesPath={projectData?.project?.imagesPath}
                  videoLink={projectData?.project?.videoLink}
                  projectName={projectData?.project?.projectName}
                  dealId={123}
                />
            )
            : projectData?.project?.company_website ? (
              <IframeComponent title={"DocumentCreatorIftame"} source={projectData?.project?.company_website} />
            ) : (
              ""
            )}
          </>
        )}

        {
          showLogs && <LogsDisplay logs={projectLogs} setShowLogs={setShowLogs} />
        }


        {user.is_superuser && dealDetails && (
          <>
            <DealDetailHeader
              dealDetail={dealDetails?.deal_info}
              userRole={"Admin"}
              setShowLogs={setShowLogs}
              showProjectLogs={showLogs}
              countViews={projectData?.countProjectViews}
            />
            <DealDetailIntro
              dealDetail={dealDetails?.deal_info}
              userRole={userRole}
            />
            <AdminDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
        {(userRole === "INV" || userRole === "FAC") && !user.is_superuser && (
          <>
            <DealDetailHeader dealDetail={dealDetails} userRole={userRole} setShowLogs={setShowLogs}
            countViews={projectData?.countProjectViews}
              showProjectLogs={showLogs} />
            <DealDetailIntro dealDetail={dealDetails} userRole={userRole} />
            <InvestorDealDetail dealDetail={dealDetails} userRole={userRole} />
          </>
        )}
      </div>
    )
  );
}

export default DealDetails;
